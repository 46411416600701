import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

function Square(props) {
    let playedSquare = '';
    if(props.value){
        playedSquare = ' played';
    }
    
    return (
        <button className={props.className + playedSquare} onClick={props.onClick}>
            {props.value}
        </button>
    );
}

class Board extends React.Component {
    renderSquare(i) {
        let className = 'square';
        if (this.props.winners && this.props.winners.includes(i)) {
            className += ' winner';
        }
        return <Square
            value={this.props.squares[i]}
            onClick={() => this.props.onClick(i)}
            className={className}
            key={i}
        />;
    }

    render() {
        //Initialize array from 0->3 for grid size
        let rows = Array.from(Array(3), (i) => i);
        let cols = Array.from(Array(3), (j) => j);
        return (
            <div>
                {rows.map((i, rowIndex) => {
                    return (
                        <div className='board-row' key={rowIndex}>
                            {cols.map((j, colIndex) => {
                                return this.renderSquare(colIndex + rowIndex * 3);
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}

class MoveList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reversed: false,
        };
    }

    render() {
        return (
            <div>
                <ol reversed={this.state.reversed}>
                    {this.state.reversed ? this.props.moves : this.props.moves.map((item) => item).reverse()}
                </ol>
                <button id='reverseButton'
                    onClick={() => this.setState({ reversed: !this.state.reversed })}
                >Reverse Order</button>
            </div>);
    }
}

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [{
                squares: Array(9).fill(null),
                pos: null,
            }],
            stepNumber: 0,
            xIsNext: true,
        }
    }
    handleClick(i) {
        const history = this.state.history.slice(0,
            this.state.stepNumber + 1);
        const current = history[history.length - 1];
        const squares = current.squares.slice();
        if (calculateWinner(squares) || squares[i]) {
            return;
        }
        squares[i] = this.state.xIsNext ? 'X' : 'O';
        this.setState({
            history: history.concat([{
                squares: squares,
                pos: i,
            }]),
            stepNumber: history.length,
            xIsNext: !this.state.xIsNext,
        });
    }

    jumpTo(step) {
        this.setState({
            stepNumber: step,
            xIsNext: (step % 2) === 0,
        });
    }

    render() {
        const history = this.state.history;
        const current = history[this.state.stepNumber];
        const winner = calculateWinner(current.squares);
        const draw = calculateDraw(current.squares);

        const moves = history.map((step, move) => {
            const desc = move ?
                'Go to move #' + move + ' (' + parseInt(step.pos / 3) + ',' + step.pos % 3 + ')' :
                'Go to game start';
            let className = 'moveList';
            if (move === this.state.stepNumber) {
                className += ' currentMove';
            }
            return (
                <li key={move}>
                    <button
                        className={className}
                        onClick={() => this.jumpTo(move)}>{desc}
                    </button>
                </li>
            );
        });

        let status;

        if (winner) {
            status = 'Winner: ' + current.squares[winner[0]];
        }
        else if (draw) {
            status = 'Draw :(';
        }
        else {
            status = 'Next player: ' + (this.state.xIsNext ? 'X' : 'O');
        }

        return (
            <div className='game'>
                <span className='game-status'>{status}</span>
                <div className='game-board'>
                    <Board
                        squares={current.squares}
                        winners={winner}
                        onClick={(i) => this.handleClick(i)} />
                </div>
                <div className='game-info'>
                    <MoveList moves={moves} />
                </div>
            </div>
        );
    }
}

// ========================================

ReactDOM.render(
    <Game />,
    document.getElementById('root')
);



function calculateWinner(squares) {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return [a, b, c];
        }
    }
    return null;
}

function calculateDraw(squares) {
    for (let i = 0; i < 9; i++) {
        if (squares[i]) continue;
        else return false;
    }
    return true;
}